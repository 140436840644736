// https://divdev.io/animating-gatsby-pt/
import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import * as cennikStyles from '../styles/cennik.module.css'

const CennikPage = () => (
  <>
    <SEO title="Cennik zajęć - MusicSpot" />
    <section className="sub-page-header" style={{ padding: '55px' }}>
      <h1>Cennik</h1>
    </section>
    <section className={`${cennikStyles.cennikContent} wrapper`}>
      <p
        className="text-uppercase"
        style={{ fontWeight: 900, fontSize: '30px', lineHeight: '36px' }}
      >
        Darmowa lekcja próbna 30 min
      </p>
      <p
        style={{
          fontWeight: 700,
          fontSize: '23px',
          lineHeight: '28px',
        }}
      >
        Zajęcia 45 minutowe - Miesięczna opłata za cztery lekcje 440 -  zł
      </p>

      <p
        style={{
          fontWeight: 700,
          fontSize: '23px',
          lineHeight: '28px',
        }}
      >
        Zajęcia 60 minutowe - Miesięczna opłata za cztery lekcje - 560 zł
      </p>
      <p style={{ marginTop: '50px' }}>
        Stała opłata miesięczna obejmuje cztery lekcje w danym miesiącu. W
        wypadku dni świątecznych/ wolnych od pracy/ zapowiedzianej nieobecności
        ucznia, lekcja zostanie odrobiona we wspólnie ustalonym terminie.
      </p>
    </section>
  </>
)

export default CennikPage
